<template>
  <div class="hello">
    <!-- <div> -->
      <!-- <img src="../assets/banner.jpg" class="img-resp banner-img-mar-set" /> -->
      <div class="hero">

      </div>
<div class="sep-white" id="diamond"></div>

    <div class="bg2-cont">
      <div class="container" id="">
        <div class="text-box ml-box"
          data-aos="fade-right"
>
          <h2>Diamonds</h2>
          <p>
Diamonds are traded worldwide, bringing together hundreds and thousands of people.
The journey of a diamond starts in a mine that can be located anywhere from distant Australia to Sub Saharan Africa to Snowy Canada, on those mines diamonds at that stage known as rough diamonds are mined in kimberlite excavates.
These rough diamonds then end up with diamond dealers aggregating and sorting the diamonds and shipping them to traders and jewelers who turn them into works of art before they get to their destination at the customers' hands.
          </p>
        </div>
      </div>
    </div>
    <div class="sep-white" id="about"></div>

    <div class="bg3-cont">
      <div class="container" >
        <div class="text-box ml-auto mr-box"
          data-aos="fade-right">
        <h2>Our Story</h2>
        <p>
          Providing leading jewellers around the world with a diverse and vast range of polished/cut diamonds, Raven star is one of the largest and well-respected diamond suppliers.

Raven star Group has been providing their services to jewellery industry globally for over 70 years. 

We are a Global group and our offices are located in different parts of the world.

We are today regarded as one of the best diamond suppliers in the world but will never lose sight of our roots as a family business, committed to our founding principles of mutual respect, excellence, dedicated service, Integrity, and transparency.

        </p>
        </div>
      </div>
    </div>
    <div class="sep-white" id="manf"></div>

    <div class="bg4-cont">
      <div class="container">
        <div class="text-box ml-box"
          data-aos="fade-right"
>
          <h2>Diamond Manufacturing</h2>
          <p>
Our diamond manufacturing units are equipped with state-of-the-art machinery and technology along with highly trained and artistic workers.

Our manufacturing group fulfills all your jewelry needs, from designing to having the finished polished gem in your jewellery of your choice.

Our professional workers deliver out progressive and splendid designs which are transformed into portions of bewitching splendor and brilliant craftsmanship.

To meet the evolving needs of its international clientele, Raven star Group continually mine and develop a first rated diamond inventory.

From mining to manufacturing, cutting and polishing, Raven star Group has generations of experience.

Raven star's strength is in the cutting and polishing of diomonds. 
There are few companies that can provide that service and Raven star is one of them.

          </p>
        </div>
      </div>
    </div>  
    <div class="sep-white" id="strg" ></div>

        <div class="bg5-cont">
      <div class="container">
        <div class="text-box ml-auto mr-box"
          data-aos="fade-right">
        <h2>Our Strength</h2>
        <p>
          Craftsmanship is our strongest ability, which we possess in diamond manufacturing.

It is out of the few few companies that we hold the capability of cutting, this makes us strong in the diamond manufacturing business.


        </p>
        </div>
      </div>
    </div>  
    <div class="sep-white" id="mgmt"></div>

        <div class="bg6-cont">
      <div class="container">
        <div class="text-box ml-box"
          data-aos="fade-right"
>
          <h2>Management</h2>
          <p>
At Ravenstar , we have a sturdy administration group comprising of the second and third  generations from the family, who are strongly dedicated to the mission and values of the company. They constantly attempt to make certain the success of the company.

          </p>
        </div>
      </div>
    </div>  
    <div class="sep-white"></div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
}
</script>

<style scoped lang="scss">

// .logo-align{
//   text-align: right;
// }



.hero{
  background: url('~@/assets/banner.jpg') center right no-repeat;
  width: 100%;
  height: 500px;
  background-size: cover;
  // margin-bottom: 20px;
}
.bg1-cont{
  background: url('~@/assets/bg1.jpg') center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 20vh 0 ;
  // margin-bottom: 20px;
}
.bg2-cont{
  text-align: left;
  background: url('~@/assets/bg2.jpg') center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 20px 0 50px;
  // margin-bottom: 20px;
}
.bg3-cont{
  text-align: left;
  background: url('~@/assets/bg-a.jpg') -10vw center no-repeat #3c312c;
  -webkit-background-size: 80%;
  -moz-background-size: 80%;
  -o-background-size: 80%;
  background-size: 80%;
  background-size: 80%;
  padding: 20px 0 50px;
  // margin-bottom: 20px;
}
.bg4-cont{
  text-align: left;
  background: url('~@/assets/bg-b.jpg') 30vw center no-repeat #334d5e;
  -webkit-background-size: 70%;
  -moz-background-size: 70%;
  -o-background-size: 70%;
  background-size: 70%;
  padding: 20px 0 50px;
  // margin-bottom: 20px;
}
.bg5-cont{
  text-align: left;
  background: url('~@/assets/bg-c.png') 4vw center no-repeat  #3c312c;
  -webkit-background-size: 57%;
  -moz-background-size: 57%;
  -o-background-size: 57%;
  background-size: 57%;
  background-size: 57%;
  padding: 20px 0 50px;
  // margin-bottom: 20px;
}
.bg6-cont{
  text-align: left;
  background: url('~@/assets/bg-d.jpg') 30vw center no-repeat #334d5e;
  -webkit-background-size: 70%;
  -moz-background-size: 70%;
  -o-background-size: 70%;
  background-size: 70%;
  padding: 20px 0 50px;
  // margin-bottom: 20px;
}


// @media (max-width: 768px) {
//   .hideOverflowOnMobile {
//     overflow: hidden;
//   }
// }
// .card {
//   overflow-x: hidden;
// }
// h3 {
//   margin: 40px 0 0;
// }
// ul {
//   list-style-type: none;
//   padding: 0;
// }
// li {
//   display: inline-block;
//   margin: 0 10px;
// }
</style>