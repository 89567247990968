<template>
  <div id="nav">
    <div class="logo-align">
      <img alt="Ravenstar logo" src="@/assets/logo.png" class="logo">
    </div>
    <div class="pad-right-45">
    <router-link to="/#home">Home</router-link> |
    <!-- <a href="/#home">Home</a> | -->
    <a href="/#about">Our Story</a> |
    <!-- <a href="#about">Our Story</a> | -->
    <a href="/#manf">Manufacturing</a> |
    <a href="/#strg">Our Strength</a> |
    <a href="/#mgmt">Management</a> |
    <router-link to="/lab">Lab Grown</router-link> |
    <router-link to="/token">Tokenization</router-link> |
    <a href="#contact">Contact Us</a>
    
    </div>
    <!-- <div class="pad-right-45">
      <div class="dropdown">
        <router-link to="/#">Home</router-link>
        <div class="dropdown-content">
          <a href="/#diamond">Diamonds</a>
          <a href="/#about">Our Story</a>
          <a href="/#manf">Manufacturing</a>
          <a href="/#strg">Our Strength</a>
          <a href="/#mgmt">Management</a>
        </div>
      </div> | 
      <div class="dropdown">
        <router-link to="/lab">Lab Grown</router-link>
        <div class="dropdown-content">
          <a href="/lab#free">Mining free</a>
          <a href="/lab#beauty">Beauty & Quality</a>
          <a href="/lab#value">Value</a>
          <a href="/lab#man">Man-Made Diamonds</a>
        </div>
      </div> |
      <a href="#contact">Contact Us</a>
    </div> -->

  </div>
  <router-view/>
  <div class="contact">
    <div class="contact-box-holder">
      <div class="map" data-aos="fade-right"></div>
      <div data-aos="fade-right">
        <div>
          <img class="icon-img" src="https://img.icons8.com/ios/50/ffffff/marker--v1.png"/><b>Address</b><br><br>
  69 - Victor Business Center,<br>13th Floor , Sky Lobby, <br>Barjuman Bussiness Tower, <br>BurDubai, Dubai UAE
          </div>
<br>
          <div>
          <img class="icon-img" src="https://img.icons8.com/ios/50/ffffff/phone.png"/><b>Phone</b><br><br>
  +971 4 456 6874
          </div>
<br>
          <div>
          <img class="icon-img2" src="https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/ffffff/external-email-interface-kiranshastry-lineal-kiranshastry.png"/><b>Email</b><br><br>
  info@ravenstar.io
          </div>

        </div>
      <div class="cont-form" data-aos="fade-right">
        <form class="">
          <input type="text" placeholder="Name" /><br>
          <input type="email" placeholder="Email" /><br>
          <input type="text" placeholder="Contact No." /><br>
          <textarea placeholder="Your message here"></textarea><br>
          <input class="submit-btn" type="submit" name="Send" value="Send" />
        </form>
      </div>
    </div>
  </div>
  <div class="home-footer" style="text-align: center;">
    Copyright © 2021 RAVENSTAR
  </div>
</template>

<style lang="scss">

// @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300&display=swap');

@font-face {
  font-family: "thryn";
  src: url('~@/assets/Thryn.ttf');
}

// .dropbtn {
//   background-color: #04AA6D;
//   color: white;
//   padding: 16px;
//   font-size: 16px;
//   border: none;
// }

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {color: #29aae1;}

.dropdown:hover .dropdown-content {display: block;}

// .dropdown:hover .dropbtn {background-color: #3e8e41;}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #5e5e5e; 
}

/* ::-webkit-scrollbar-track:hover {
  background: #fff; 
} */
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #29aae1;
  border-radius: 20px;
}

html {
  scroll-behavior: smooth;
}

body{
  padding: 0;
  margin: 0;
}

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: Thryn, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #29aae1;
  background-color: #fff;
}

h1, h2, h3, h4, h5, h6, a{
  // font-family: 'Raleway', sans-serif;
  font-family: 'Darker Grotesque', sans-serif;
  font-weight: 500;
  color: #fff;
}

#nav {
  padding: 5px 10px;
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    font-weight: bold;
    // color: #2c3e50;
    color: #a0a0a0;
    text-decoration: none;
    &:hover{
      color: #29aae1;
    }
    &.router-link-exact-active {
      color: #29aae1;
    }
  }
}

.logo{
  width: 60px;
  margin-left: 15px;
}

.pad-right-45{
  padding-right: 45px;
}

.container{
  max-width: 1920px;
  // padding: 8px;
  width: 100%;
  margin: 0 auto;
}

p{
  margin-top: 0;
  margin-bottom: 0;
  font-size: 13px;
  text-align: justify;
  color: #fff;
}

.img-resp{
  max-width: 100%;
  width: auto;
}

.banner-img-mar-set{
  margin-top: 85px;
  margin-bottom: 15px;
}

.sep-white{
  width: 100%;
  height: 20px;
  background: #fff;
}
.text-box{
  width: 300px;
}

.ml-box{
  margin-left: 80px;
}

.mr-box{
  margin-right: 80px;
}

.ml-auto{
  margin-left: auto;
}

.contact-box-holder{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
  background: rgba(60, 49, 44, .7);
}

.contact-box-holder div{
  flex: 1 1 30%; /*grow | shrink | basis */
}

.icon-img{
  margin: 0px 5px -2px 5px;
  width: 16px;
}

.icon-img2{
  margin: 0px 5px -3px 5px;
  width: 16px;
}

.contact{
  color: #fff;
  font-size: 13px;
}

.map{
  background: url('~@/assets/map.png') center no-repeat;
  height: 250px;
  background-size: cover;
  border-right: 1px solid #fff;
}

.cont-form{
  border-left: 1px solid #fff;
}

input, textarea{
  font-family: Thryn, Helvetica, Arial, sans-serif;
  width: 80%;
  margin: 5px 8%;
  padding: 15px 5px;
  border-radius: 5px;
  border: 0;
}

.submit-btn{
  background: #3c312c;
    color: #fff;
}

@media screen and (max-width: 1345px) {
  
  .ml-box{
    margin-left: 25px;
  }

  .mr-box{
    margin-right: 25px;
  }

}

[data-aos="new-animation"] {
  opacity: 0;
  transition-property: transform, opacity;
  &.aos-animate {
    opacity: 1;
  }
  @media screen and (min-width: 768px) {
    transform: translateX(100px);
    &.aos-animate {
      transform: translateX(0);
    }
  }
}
</style>
